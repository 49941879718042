export default function pathsForIcon(paths) {
  const elements = paths.map((path) => {
    return `<path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="${path}"
            />`;
  });

  return elements.join("");
}
